<template>
	<div class="wa">

	</div>
</template>

<script>
	import {
		Toast
	} from "vant";

	export default {
		components: {},
		name: 'wa',
		data() {
			return { 
			};
		},
		components: {},
		watch: {},
		created() {
			this.getWhatApp() 
		},
		mounted() {},
		methods: {
			getWhatApp() {
				this.$axios
					.get(this.$api.getWhatApp)
					.then(res => {
						console.log(res.data)
						if (res.data.code == 0) {
							// sessionStorage.setItem('whatApp', res.data.data); 
							// 测试使用
							// window.location.href = 'https://wa.me/85291496563';
							// 正式使用
							// window.location.href = ;
							window.open(res.data.data)
							} else {
							console.log('失败')
						}
					})
					.catch((res) => {
						console.log('失败', res)
					})
			}

		},
		computed: {},
	};
</script>

<style>
</style>
